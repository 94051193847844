//8-sep-24 7:18pm

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import EmailComposer from './EmailComposer';

import './App.css';

function App() {
  return (
    <Router>
      <div className="app-container">
        <nav className="navbar">
         
          {/* <Link to="/" className="nav-link">Compose Email</Link> */}
          {/* External Link */}
          {/* <a 
            href="https://newsletterhome.marriedtothemopnewsletter.online/" 
            //target="_blank" 
            rel="noopener noreferrer" 
            className="nav-link"
          >
            Go to Newsletter Home
          </a> */}
        </nav>
        <Routes>
        
          <Route path="/" element={<EmailComposer />} />
         
        </Routes>
      </div>
    </Router>
  );
}

export default App;


//8-sep-24 7:17pm

import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import './EmailComposer.css';
import logo from './logo.png'; // Ensure you have a logo.png file in your project

function EmailComposer() {
  const [subject, setSubject] = useState('');
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [files, setFiles] = useState([]);
  const [uploadMessage, setUploadMessage] = useState('');
  const quillRef = useRef(null);

  useEffect(() => {
    // Initialize Quill editor
    if (!quillRef.current) {
      quillRef.current = new Quill('#editor-container', {
        theme: 'snow',
        modules: {
          toolbar: [
            [{ header: '1' }, { header: '2' }],
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link', 'image']
          ]
        }
      });

      // Handle image pasting
      quillRef.current.getModule('toolbar').addHandler('image', () => {
        document.querySelector('#file-input').click();
      });
    }

    // Fetch groups from the server
        console.log('fetching groups');
    axios.get('https://compose.marriedtothemopnewsletter.online/groups')
    //axios.get('http://localhost:3008/groups')
      .then(response => {
        setGroups(response.data.groups);
      })
      .catch(error => {
        console.error('Error fetching groups:', error);
      });
  }, []);

  const handleFileUpload = (e) => {
    const filesArray = Array.from(e.target.files);
    // Validate file types and sizes here if needed
    setFiles(prevFiles => [...prevFiles, ...filesArray]);
    setUploadMessage('File(s) uploaded successfully');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //const editorHtml = quillRef.current ? quillRef.current.root.innerHTML : '';
    const editorHtml = quillRef.current ? quillRef.current.root.innerHTML : '';

  // Add the logo and email footer content
  //const logoImgTag = `<img src="cid:logo" alt="Logo" style="width:100px; height:auto;" />`;
  const senderEmail = 'andy@marriedtothemop.com'; // Replace with your email
  const emailFooter = `<p>Best regards,</p>
                       <p>${senderEmail}</p>
                       <p>If you wish to unsubscribe, click <a href="https://unsubscribe.marriedtothemopnewsletter.online/">here</a>.</p>`;
  
  // Combine the email content
  const fullEmailHtml = `${editorHtml}${emailFooter}`; // Final email body


    const formData = new FormData();
    formData.append('subject', subject);
    formData.append('body', fullEmailHtml);
    formData.append('group', selectedGroup);

    // Append files to FormData
    files.forEach(file => {
      formData.append('attachments', file);
    });

    //  // Attach logo
    // formData.append('attachments', {
    // filename: 'logo.png',    // Make sure to use the correct filename
    // path: './src/logo.png', // Path to the image file
    // cid: 'logo'              // The cid used in the HTML img tag
    // });

    try {
      console.log('sending email');
      const response = await axios.post('https://compose.marriedtothemopnewsletter.online/compose', formData, {
      //const response = await axios.post('http://localhost:3008/compose', formData, {  
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      alert('Email sent successfully');
      setFiles([]); // Clear files after sending
      setSubject('');
      setSelectedGroup('');
      if (quillRef.current) {
        quillRef.current.root.innerHTML = ''; // Clear the editor
      }
    } catch (error) {
      console.error('There was an error!', error);
      alert('Failed to send email');
    }
  };

  return (
    <div className="email-composer">
      <div className="header">
        <img src={logo} alt="Logo" className="logo" />
        <h1>Email Composer</h1>
      </div>
      <form onSubmit={handleSubmit} className="form-container">
        <div className="form-group">
          <label>Subject:</label>
          <input
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            className="form-control"
            required
          />
        </div>
        <div className="form-group">
          <label>Group:</label>
          <select
            value={selectedGroup}
            onChange={(e) => setSelectedGroup(e.target.value)}
            className="form-control"
            required
          >
            <option value="">Select a group</option>
            {groups.map((group, index) => (
              <option key={index} value={group}>{group}</option>
            ))}
          </select>
        </div>
        <div id="editor-container" className="editor-container"></div>
        <input
          id="file-input"
          type="file"
          accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          multiple
          onChange={handleFileUpload}
          style={{ display: 'none' }}
        />
        <button
          type="button"
          className="btn btn-secondary attach-button"
          onClick={() => document.querySelector('#file-input').click()}
        >
          Attach File
        </button>
        {files.length > 0 && (
          <div className="uploaded-files">
            <h4>Uploaded Files:</h4>
            <ul>
              {files.map((file, index) => (
                <li key={index}>{file.name}</li>
              ))}
            </ul>
          </div>
        )}
        {uploadMessage && <p className="upload-message">{uploadMessage}</p>}
        <button type="submit" className="btn btn-primary send-button">Send Email</button>
      </form>
    </div>
  );
}

export default EmailComposer;